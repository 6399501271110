import * as leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';

// fix for marker icon using webpack
// delete L.Icon.Default.prototype._getIconUrl;
Reflect.deleteProperty(L.Icon.Default.prototype, '_getIconUrl');
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

window.L = leaflet;

